<template>
  <b-sidebar
    id="add-new-chatter-sidebar"
    :visible="isAddNewChatterSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    :no-close-on-backdrop="true"
    @shown="sideBarShown"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-chatter-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ chatterId > 0 ? 'Редактировать форум' : 'Добавить новый форум' }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Наименование -->
          <validation-provider
            #default="validationContext"
            name="тема"
            rules="required"
          >
            <b-form-group
              label="Тема"
              label-for="title"
            >
              <b-form-input
                id="title"
                v-model="userData.title"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                autocomplete="off"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Описание -->
          <b-form-group
            v-if="chatterId <= 0"
            label="Вопрос"
            label-for="description"
          >
            <quill-editor
              id="description"
              v-model="userData.question"
              :options="editorOption"
            />
          </b-form-group>

          <!-- Пользователь -->
          <div v-if="userDataStorage.roles && userDataStorage.roles.length > 0 && userDataStorage.roles[0] === 'admin'">
            <validation-provider
                #default="validationContext"
                name="пользователь"
                rules="required"
            >
              <b-form-group
                  label="Пользователь"
                  label-for="mentor"
                  :state="getValidationState(validationContext)"
              >
                <v-select
                    v-model="userData.user_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="usersOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="mentor"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </div>

          <!-- Курс -->
          <validation-provider
            #default="validationContext"
            name="категория"
            rules="required"
          >
            <b-form-group
              label="Категория"
              label-for="category"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.chatter_category_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="categoryOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="category"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Сохранить
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Отменить
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BMedia,
  BAvatar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import ru from 'vee-validate/dist/locale/ru.json'
import { ref } from '@vue/composition-api'
import {
  required, alphaNum, email, regex,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import http from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import ImageResize from 'quill-image-resize-vue'
import * as Quill from 'quill'
import Ripple from 'vue-ripple-directive'

Quill.register('modules/imageResize', ImageResize)

export default {
  components: {
    BSidebar,
    BMedia,
    BAvatar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    ImageResize,

  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewChatterSidebarActive',
    event: 'update:is-add-new-chatter-sidebar-active',
  },
  props: {
    isAddNewChatterSidebarActive: {
      type: Boolean,
      required: true,
    },
    chatterId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      userDataStorage: JSON.parse(localStorage.getItem('userData')),
      required,
      alphaNum,
      email,
      regex,
      usersOptions: [],
      categoryOptions: [],
      editorOption: {
        theme: 'snow',
        bounds: '#scrolling-container',
        scrollingContainer: '#scrolling-container',
        placeholder: 'Описание',
        modules: {
          imageResize: {
          },
          toolbar: {
            container: [
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ['bold', 'italic', 'underline', 'strike'],
              [{ color: [] }, { background: [] }],
              [{ script: 'super' }, { script: 'sub' }],
              [{ header: '1' }, { header: '2' }, 'blockquote', 'code-block'],
              [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
              [{ direction: 'rtl' }, { align: [] }],
              ['link', 'image', 'video', 'formula'],
              ['clean'],
            ],
          },
        },
      },

    }
  },
  created() {
    this.userDataStorage = JSON.parse(localStorage.getItem('userData'))
  },
  methods: {
    sideBarShown() {
      this.getUsers()
      this.getCategories()
      this.getChatter()
    },
    getUsers() {
      this.$http
        .get('https://edu.startupchoikhona.tj/backend/api/users', {
          params: {
          },
        })
        .then(response => {
          this.usersOptions = response.data.data
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка при получение менторы',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getCategories() {
      this.$http
        .post('https://edu.startupchoikhona.tj/backend/api/chatter-category/index', {
        })
        .then(response => {
          this.categoryOptions = response.data
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка при получение менторы',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getChatter() {
      if (this.chatterId > 0) {
        this.$http
          .post(`https://edu.startupchoikhona.tj/backend/api/chatter-discussion/${this.chatterId}/view`, {
          })
          .then(response => {
            const {
              data,
            } = response.data
            this.userData = {
              user_id: data.user_id,
              chatter_category_id: data.chatter_category_id,
              title: data.title,
              question: '',
            }
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Ошибка при получение менторы',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      } else {
        this.userData = {
          user_id: null,
          chatter_category_id: null,
          title: '',
          question: '',
        }
      }
    },
  },
  setup(props, { emit }) {
    const blankUserData = {
      user_id: null,
      chatter_category_id: null,
      title: '',
      question: '',
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const buildFormData = (formData, data, parentKey) => {
      if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data)
          .forEach(key => {
            if (data[key] && key !== 'question') {
              buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
            }
          })
      } else {
        const value = data == null ? '' : data

        formData.append(parentKey, value)
      }
    }

    const onSubmit = () => {
      const formData = new FormData()
      console.log(userData)
      buildFormData(formData, userData.value)
      if (props.chatterId > 0) {
        http.post(`https://edu.startupchoikhona.tj/backend/api/chatter-discussion/update/${props.chatterId}`, formData)
          .then(() => {
            emit('refetch-data')
            emit('update:is-add-new-chatter-sidebar-active', false)
          })
      } else {
        http.post('https://edu.startupchoikhona.tj/backend/api/chatter-discussion/create', formData)
          .then(response => {
            const { data } = response.data
            http.post('https://edu.startupchoikhona.tj/backend/api/chatter-post/create', {
              chatter_discussion_id: data.id,
              body: userData.value.question,
              user_id: userData.value.user_id,
            })
              .then(() => {
                emit('refetch-data')
                emit('update:is-add-new-chatter-sidebar-active', false)
                this.$router.replace({ name: 'forum-post-page-dashboard', params: { id: data.id }, })
              })
          })
      }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)
    localize('ru', ru)
    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-chatter-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

#description .ql-editor {
  min-height: 300px;
}
</style>
